<script setup>
// import fallback from "~/transitions/fallback";

const config = useRuntimeConfig().public;
const route = useRoute();
const { seomatic, bodyScripts } = await useSeomatic();

useHead(seomatic);

useHead({
  htmlAttrs: { lang: "en" },
  meta: [
    { name: "robots", content: "noindex" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
  ],
  script: computed(() => [
    route.query["x-craft-live-preview"] ? { src: "/iframeResizer.min.js" } : {},
  ]),
});

// name after '/' becomes key on the globalData object
// global/footer-menu -> globalData.value.footerMenu
const globalDataEndpoints = [
  "global/footer",
  "global/events",
  "global/jobs",
  "menu/main-menu",
];

const { globalData, setGlobalData } = useTTCState();

await callOnce(async () => {
  try {
    // run in parallel to speed things up
    const responses = await Promise.allSettled(
      globalDataEndpoints.map((url) => useCmsFetch(url)),
    );

    const globalData = responses.reduce((acc, cur, idx) => {
      if (cur.status !== "fulfilled") return;

      const key = camelize(globalDataEndpoints[idx].split("/").at(-1));
      return { ...acc, [key]: cur.value.data };
    }, {});

    setGlobalData(globalData);
  } catch (error) {
    throw createError({
      statusCode: 500,
      statusMessage: "Could not get global data",
    });
  }
});
// const { currentScroll } = useScreen();
// const app = ref(null);
// const pageIsTransitioning = ref(false);
// const pageTransitions = usePageTransition({
//   defaultTransition: fallback,
//   transitions: {},
//   globalHooks: {
//     onBeforeLeave(el) {
//       pageIsTransitioning.value = true;
//       // place the old page in the place it was,
//       // so it's safe to scroll to the top
//       el.style.position = "fixed";
//       el.style.width = "100vw";
//       el.style.left = 0;
//       el.style.top = currentScroll.value * -1 + "px";
//     },
//     onEnter(el) {},
//     onAfterEnter(el) {
//       pageIsTransitioning.value = false;
//       el.removeAttribute("style");
//     },
//   },
// });

const { isMobileOrTablet } = useDevice();
const { width: innerWidth } = useWindowSize();

const isMobile = computed(() => {
  if (process.server) return isMobileOrTablet;
  return innerWidth.value < 900;
});
</script>

<template>
  <main class="app flex h-full flex-col" ref="app">
    <div
      v-if="bodyScripts?.length"
      v-for="bodyScript in bodyScripts"
      class="seomatic-body-script"
      :key="bodyScript"
      v-html="bodyScript"
    />
    <globals-dev-grid />
    <client-only>
      <transition name="page" mode="out-in">
        <globals-header :key="route.path" /> </transition
    ></client-only>
    <globals-colors />

    <NuxtLayout :class="{ '-mt-[var(--header-height)]': isMobile }">
      <NuxtPage class="page flex flex-col" />
      <!-- :transition="pageTransitions" -->
    </NuxtLayout>
    <transition name="page" mode="out-in">
      <globals-footer
        :key="route.path"
        class="mt-auto"
        v-if="route.name !== 'job-slug' && route.name !== 'events-slug'"
      />
    </transition>
  </main>
</template>

<style>
@media (min-width: 768px) {
  .page-enter-active,
  .page-leave-active {
    transition: all 0.3s ease-out;
  }
  .page-enter-from,
  .page-leave-to {
    opacity: 0;
    filter: blur(1rem);
  }
}
</style>
